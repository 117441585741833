import React from 'react';
import '../../App.css';
import '../../Mobile.css';
import CssBaseline from '@mui/material/CssBaseline';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import AppRouter from '../../router';
import {Helmet} from 'react-helmet';
import Web3Wrapper from '../Web3Wrapper';
import {APP_LOGO} from '../../constants';

const LIVE_STRIPE_API_KEY =
  'pk_live_51KqNaBCZAHkf7WFYWkuGK60oTILpgNT03NekN1wRNb3cgE37m62ziRSYt5R4EHXsFbdRuarDIuDIUovRy9Gzt4gG00CRb0R040';

const TEST_STRIPE_API_KEY =
  'pk_test_51KqNaBCZAHkf7WFYSaQbsNmOmTg3l9lwLd12w5t3dzSGV9e2UocBZTt3KEeESBMLYOn9mV6FLOCUfZcBppn0OJWK00W2kocmkR';

const stripePromise = loadStripe(LIVE_STRIPE_API_KEY);

const FONTS = [
  {
    cssSrc: 'https://fonts.googleapis.com/css?family=Saira:400',
  },
];

const App = () => {
  return (
    <Web3Wrapper>
      <Elements options={{fonts: FONTS}} stripe={stripePromise}>
        <div className="App">
          <CssBaseline />

          <Helmet
            description="Personalized Doodles from your favorite people."
            titleTemplate="Getadoodle | %s"
            defaultTitle="Getadoodle">
            <meta property="og:image" content={APP_LOGO} />
          </Helmet>

          <AppRouter />
        </div>
      </Elements>
    </Web3Wrapper>
  );
};

export default App;
