import React from 'react';
import {Container} from '@mui/material';
import {Link} from 'react-router-dom';

const TalentTermsOfService = () => {
  const sections = [
    {
      title: '1. Participation in Getadoodle',
      content:
        'You must register with accurate information and maintain account security. Getadoodle is available to users 4 years and older. Organizations may register Talent Users with proper authorization.',
    },
    {
      title: '2. Getadoodle Doodles & Stickers',
      content:
        'Users can commission custom doodles ("Getadoodle Doodles"), and Talent Users set their own prices (minimum $5). You have seven days to fulfill requests, and unfulfilled or canceled requests will not be paid. Users can also purchase stickers ("Getadoodle Stickers"), which may be gifted to anyone on the platform. Getadoodle Stickers are for personal, non-commercial use within the platform and may not be resold, transferred, or exchanged for monetary value outside Getadoodle. Getadoodle Sticker prices are set by Getadoodle, and Getadoodle retains full control over their pricing, distribution, and availability. Revenue is shared under the same 75/25 split after platform fees as commissions.',
    },
    {
      title: '3. Fees & Payments',
      content:
        "Talent Users receive 75% of net revenue after platform fees from commissioned Getadoodle Doodles and Getadoodle Sticker purchases. Getadoodle retains 25% after deducting third-party platform fees (such as Apple's 15-30% fee for in-app purchases and Stripe's 2.9% + $0.30 for web transactions). Talent Users set their own prices for commissions but not for stickers. Payments are processed via a third-party provider. If donating proceeds to charity, you must comply with legal requirements.",
    },
    {
      title: '4. Talent Referral Program',
      content:
        "Talent Users may refer new Talent Users via a unique referral code. If the referred Talent User is accepted, you receive 5% of their earnings for one year, subject to verification and compliance with the program's terms.",
    },
    {
      title: '5. Talent Content',
      content:
        'By submitting content, you grant Getadoodle a non-exclusive, royalty-free license to use and promote it. Selling Getadoodle Doodles or Getadoodle Stickers outside the platform requires prior approval, and Getadoodle is not obligated to remove publicly shared content.',
    },
    {
      title: '6. Ownership',
      content:
        'You retain ownership of your content but grant Getadoodle a broad usage license. Feedback submitted may be used without obligation to compensate you.',
    },
    {
      title: '7. Additional Terms',
      content:
        'Certain features on the Site may be subject to additional terms, which will be presented before use.',
    },
    {
      title: '8. Eligibility',
      content:
        'You must be at least 13 years old, with parental consent required for minors under 13 years old. If you are under 4 years old, you may not use Getadoodle.',
    },
    {
      title: '9. Copyright & Intellectual Property',
      content:
        'If you believe your copyrighted work has been infringed, contact our Copyright Agent at agent@getadoodle.com.',
    },
    {
      title: '10. Privacy',
      content:
        'Your use of the Site is subject to our <a href="/privacy">Privacy Policy</a>.',
    },
    {
      title: '11. Third-Party Content & Interactions',
      content:
        'Getadoodle is not responsible for third-party content on the Site, and you may not contact Users outside the platform.',
    },
    {
      title: '12. Business Relationship',
      content:
        'You are an independent contractor, not an employee or agent of Getadoodle. You are responsible for all taxes and reporting obligations.',
    },
    {
      title: '13. Links',
      content:
        'The Site may contain links to third-party websites, and Getadoodle is not responsible for their content.',
    },
    {
      title: '14. Site Changes',
      content:
        'Getadoodle may modify or discontinue any part of the Site at any time without notice.',
    },
    {
      title: '15. Termination',
      content:
        'You may cancel your account by contacting talentsuccess@getadoodle.com. Getadoodle reserves the right to terminate accounts at its discretion.',
    },
    {
      title: '16. Disclaimers & Liability',
      content:
        'The Site is provided "as is," with no guarantees of availability or performance. Getadoodle is not liable for indirect or consequential damages, and liability is limited to fees paid by you in the previous 12 months.',
    },
    {
      title: '17. Indemnification',
      content:
        'You agree to indemnify Getadoodle against claims, losses, and legal fees arising from your use of the Site.',
    },
    {
      title: '18. Arbitration',
      content:
        'Disputes must be resolved through binding arbitration, and class action lawsuits are prohibited. You may opt out within 30 days by sending written notice to Getadoodle Inc., talentsuccess@getadoodle.com.',
    },
    {
      title: '19. Other Provisions',
      content:
        'These Terms are governed by California law, and disputes must be litigated in Los Angeles, CA. If any provision is found unenforceable, the remainder will continue in effect.',
    },
    {
      title: '20. Changes to These Terms',
      content:
        'We may update these Terms and will post revisions on the Site. Continued use of the Site constitutes acceptance of the updated Terms.',
    },
    {
      title: '21. End User License Agreement (EULA)',
      content:
        'By using the Getadoodle app, you agree to the End User License Agreement (EULA) outlined in the Site Terms of Service. Getadoodle grants you a limited, revocable, non-exclusive, non-transferable license for personal, non-commercial use. You may not modify, reverse-engineer, create derivative works, circumvent security features, or use the app for unlawful purposes. Getadoodle may provide automatic updates and may terminate this license if you violate these Terms.',
    },
  ];

  return (
    <Container>
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-800 mb-2">
            Talent Terms of Service
          </h1>
          <p className="text-gray-600" style={{fontStyle: 'italic'}}>
            Last Updated March 9, 2024
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-xl font-semibold text-gray-800 mb-3">
            Introduction
          </h2>
          <p className="text-gray-700 mb-4">
            These Terms govern your use of Getadoodle as a Talent User, covering
            our website (getadoodle.com), mobile app, and related services. By
            using the Site, you agree to these Terms. These Terms apply only to
            Talent Users, and additional policies, including our Acceptable Use
            Policy and Community Guidelines, also apply. If using the Site in
            another capacity, refer to the{' '}
            <Link to="/terms-of-service">Site Terms of Service</Link>.
          </p>
        </div>

        <div className="mb-8 p-4 bg-gray-100 rounded-md border border-gray-300">
          <p className="font-bold text-gray-800 mb-2">ARBITRATION NOTICE:</p>
          <p className="text-gray-700">
            Section 18 requires disputes to be resolved through arbitration,
            with limited exceptions. You may opt out as described in Section 18.
          </p>
        </div>

        <div className="space-y-6">
          {sections.map((section, index) => (
            <div key={index} className="mb-4">
              <h2 className="text-xl font-semibold text-gray-800 mb-2">
                {section.title}
              </h2>
              <p
                className="text-gray-700"
                dangerouslySetInnerHTML={{__html: section.content}}
              />
            </div>
          ))}
        </div>

        <div className="mt-8 pt-6 border-t border-gray-200">
          <p className="text-gray-700">
            For questions, contact talentsuccess@getadoodle.com
          </p>
        </div>
      </div>
    </Container>
  );
};

export default TalentTermsOfService;
