import React from 'react';
import {Container} from '@mui/material';
import {Link} from 'react-router-dom';

const TermsOfService = () => {
  const sections = [
    {
      title: '1. Getadoodle Marketplace',
      content:
        'You agree to provide accurate, current, and complete account information and are responsible for maintaining its confidentiality. You are responsible for any activity that occurs under your account and agree to notify us immediately of unauthorized access. Getadoodle is not liable for losses due to unauthorized use.',
    },
    {
      title: '2. Getadoodle Doodles',
      content:
        'You may commission personalized doodles ("Getadoodle Doodles") from Talent Users, who have sole discretion over the content and may decline requests. Payment is charged upon fulfillment. Getadoodle Doodles are licensed, not sold. Upon full payment, you receive a non-exclusive, sublicensable, revocable, worldwide license for personal, non-commercial use. You may not sell, resell, commercialize, or create NFTs from them without written consent. Any violation of these Terms results in license termination. Getadoodle also offers stickers for purchase and gifting. Stickers are for personal, non-commercial use within the platform and cannot be resold, transferred, or exchanged for monetary value outside Getadoodle. Misuse, including harassment or abuse, may result in removal or restriction at Getadoodle\'s discretion. Refunds are only issued as required by law or at Getadoodle\'s sole discretion.',
    },
    {
      title: '3. Acknowledgment',
      content:
        'You acknowledge that Getadoodle is not liable for any Doodle or user submissions, you have no expectation of privacy in your submitted content, and Getadoodle reserves the right to remove content or terminate access at any time.',
    },
    {
      title: '4. Additional Terms',
      content:
        'Some features or purchases may have additional terms. If those terms conflict with these Terms, the additional terms will govern.',
    },
    {
      title: '5. Eligibility',
      content:
        'Getadoodle is available for users 4 years and older. Users under 13 require parental consent to access features involving user-generated content. Getadoodle complies with COPPA and other child protection laws, limiting interactions between minors and adults where required. You may not use Getadoodle if you are prohibited by law from using the service, a previously banned user, or a convicted sex offender.',
    },
    {
      title: '6. Child Safety & Getadoodle Kids',
      content:
        'Getadoodle Kids offers a secure, moderated space for users under 13. No personal data collection. No third-party ads. No photo uploads, music posts, external links, or location metadata. No public followers/following lists. One-tap parental account deletion. If your child has shared unauthorized personal information, contact us at kids@getadoodle.com for prompt removal.',
    },
    {
      title: '7. Fees & Payment',
      content:
        'All transactions are in U.S. dollars (USD), and you agree to pay all fees at the time of purchase. All purchases are final, but if a Talent User rejects a request, you will receive a full refund in the original payment method or as a site credit. Getadoodle reserves the right to change fees with notice.',
    },
    {
      title: '8. Ownership',
      content:
        'Talent Users own the rights to their Doodles. Getadoodle retains ownership of its Site, trademarks, and proprietary content. By uploading a Doodle, Talent Users grant Getadoodle a non-exclusive, royalty-free, worldwide license to use, display, and promote the Doodle for marketing and promotional purposes.',
    },
    {
      title: '9. Copyright and Intellectual Property Policy',
      content:
        'If you believe your copyright is being infringed, you may submit a DMCA notice to agent@getadoodle.com. Repeat infringers may have their accounts terminated.',
    },
    {
      title: '10. Privacy',
      content:
        'Your privacy is important. Our Privacy Policy details how we collect, use, and share personal information.',
    },
    {
      title: '11. Third-Party Content & Interactions',
      content:
        'Getadoodle is not responsible for third-party content or interactions between users. Use discretion when engaging with others.',
    },
    {
      title: '12. Links',
      content:
        'External links may be provided. Getadoodle is not responsible for third-party websites.',
    },
    {
      title: '13. Changes to the Site',
      content: 'We may modify or discontinue features without notice.',
    },
    {
      title: '14. Termination & Reservation of Rights',
      content: 'Getadoodle may suspend or terminate access for any reason.',
    },
    {
      title: '15. Indemnification',
      content:
        'You agree to indemnify Getadoodle against any claims arising from your use of the Site.',
    },
    {
      title: '16. Disclaimers & Limitation of Liability',
      content:
        'Getadoodle is provided "as is" without warranties. We are not responsible for lost profits, personal injury, or unauthorized access.',
    },
    {
      title: '17. Arbitration Agreement & Waiver of Certain Rights',
      content:
        'All disputes will be resolved through binding arbitration under the American Arbitration Association (AAA) rules, with no class actions permitted. You may opt out of arbitration within 30 days by providing written notice.',
    },
    {
      title: '18. End User License Agreement (EULA)',
      content:
        'By installing or using the Getadoodle App, you agree to this End User License Agreement (EULA). Getadoodle grants you a limited, revocable, non-exclusive, non-transferable license for personal, non-commercial use. You may not modify, reverse-engineer, create derivative works, circumvent security features, or use the App for unlawful purposes. Getadoodle may provide automatic updates and may terminate this license if you violate these Terms.',
    },
    {
      title: '19. Other Provisions',
      content:
        'These Terms are governed by California law. Getadoodle is not liable for events beyond its control, including natural disasters or internet outages (Force Majeure). Getadoodle may assign its rights at any time.',
    },
    {
      title: '20. Changes to These Terms',
      content:
        'We may modify these Terms. If significant changes occur, we will notify you.',
    },
  ];

  return (
    <Container>
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-800 mb-2">
            Site Terms of Service
          </h1>
          <p className="text-gray-600" style={{fontStyle: 'italic'}}>
            Last Updated March 9, 2024
          </p>
        </div>

        <div className="mb-8">
          <p className="text-gray-700 mb-4">
            This Site Terms of Service Agreement ("Terms") governs your use of
            the Getadoodle™ marketplace platform offered by Getadoodle Inc.
            ("we", "us", or "Getadoodle"), including our website
            (getadoodle.com), mobile application ("App"), and services we
            provide through them (collectively, the website, App, and services
            referred to as our "Site"). "You" refers to you as a user of the
            Site.
          </p>
          <p className="text-gray-700 mb-4">
            These Terms apply to users of, including visitors to, our Site. Use
            of our Site is also subject to our current Acceptable Use Policy and
            Community Guidelines. Your use of our Site as a Talent User (defined
            below) is governed by the{' '}
            <Link to="/talent-terms-of-service">Talent Terms of Service</Link>.
            ("Talent Terms").
          </p>
          <p className="text-gray-700 mb-4 font-bold">
            PLEASE READ THESE TERMS CAREFULLY. By using our Site or otherwise
            indicating your acceptance (for example, by agreeing when creating
            or logging into your account, clicking "I Agree," etc.), you
            represent and warrant that you have read, understand, and agree to
            be bound by these Terms. If you do not agree, do not access or use
            our Site.
          </p>
        </div>

        <div className="mb-8 p-4 bg-gray-100 rounded-md border border-gray-300">
          <p className="font-bold text-gray-800 mb-2">ARBITRATION NOTICE:</p>
          <p className="text-gray-700">
            Section 17 of these Terms contains provisions governing how claims
            that you and Getadoodle have against each other are resolved,
            including any claims that arose or were asserted prior to the "Last
            Updated" date at the top of these Terms. It contains an arbitration
            agreement that will, with limited exceptions, require disputes
            between you and Getadoodle to be submitted to binding and final
            arbitration. Unless you opt-out of the arbitration agreement as
            described in Section 17: (1) you may pursue claims and seek relief
            against us only on an individual basis, not as a plaintiff or class
            member in any class or representative action or proceeding; and (2)
            you waive your right to seek relief in a court of law and to have a
            jury trial on your claims.
          </p>
        </div>

        <div className="space-y-6">
          {sections.map((section, index) => (
            <div key={index} className="mb-4">
              <h2 className="text-xl font-semibold text-gray-800 mb-2">
                {section.title}
              </h2>
              <p className="text-gray-700">{section.content}</p>
            </div>
          ))}
        </div>

        <div className="mt-8 pt-6 border-t border-gray-200">
          <p className="text-gray-700 text-center">
            By using Getadoodle, you acknowledge that you have read, understood,
            and agreed to these Terms.
          </p>
        </div>
      </div>
    </Container>
  );
};

export default TermsOfService;
