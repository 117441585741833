import {
  FormControl,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  TextField,
  InputLabel,
  Button,
} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import FunctionClient from '../../clients/Functions';

const AddressForm = ({onComplete}) => {
  const address = useRef('');
  const city = useRef('');
  const zip = useRef('');

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [codes, setCodes] = useState([]);
  const [states, setStates] = useState([]);
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    fetchCodesAsync();
  }, []);

  useEffect(() => {
    if (selectedState) {
      checkIsComplete();
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedCountry) {
      const item = codes.filter(a => a.code === selectedCountry).pop();
      if (item.states) {
        setStates(item.states);
      } else {
        setStates([]);
      }
    }
  }, [selectedCountry]);

  const fetchCodesAsync = async () => {
    const res = await FunctionClient.listCountries();
    setCodes(res);
    setSelectedCountry(res[227].code);
  };

  const onDone = e => {
    e.preventDefault();

    onComplete({
      address: address.current,
      city: city.current,
      country: selectedCountry,
      state: selectedState,
      zip: zip.current,
    });
  };

  const checkIsComplete = () => {
    setIsComplete(
      address.current &&
        city.current &&
        selectedCountry &&
        zip.current &&
        selectedState,
    );
  };

  if (!codes.length) return <LinearProgress />;

  return (
    <form onSubmit={onDone}>
      <Stack spacing={2}>
        <TextField
          required
          label="Street Address"
          onChange={e => {
            address.current = e.target.value;
          }}
        />
        <TextField
          onBlur={checkIsComplete}
          required
          label="City"
          onChange={e => {
            city.current = e.target.value;
          }}
        />
        <FormControl fullWidth>
          <InputLabel id="country-label">Country</InputLabel>
          <Select
            value={selectedCountry || ''}
            required
            label="Country"
            onChange={e => {
              setSelectedCountry(e.target.value);
              checkIsComplete();
            }}>
            {codes.map(code => (
              <MenuItem key={code.code} value={code.code}>
                {code.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {states.length > 0 && (
          <FormControl fullWidth>
            <InputLabel id="state-label">State</InputLabel>
            <Select
              required
              label="State"
              value={selectedState}
              onChange={e => {
                setSelectedState(e.target.value);
              }}>
              {states.map(code => (
                <MenuItem key={code.code} value={code.code}>
                  {code.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <TextField
          required
          onBlur={checkIsComplete}
          label="Zip"
          onChange={e => {
            zip.current = e.target.value;
          }}
        />
        <Button disabled={!isComplete} variant="contained" type="submit">
          NEXT
        </Button>
      </Stack>
    </form>
  );
};

export default AddressForm;
