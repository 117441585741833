import React from 'react';
import {Container} from '@mui/material';
import {Link} from 'react-router-dom';

const PrivacyPolicy = () => {
  const sections = [
    {
      title: '1. Scope of Getadoodle Privacy Policy',
      content:
        'This Privacy Policy applies to all aspects of our Site and any information we collect offline. The Site may link to or incorporate third-party websites, platforms, or services over which we have no control. These third parties have their own privacy policies and business practices, and we are not responsible for their security, privacy, or practices.',
    },
    {
      title: '2. Information We Collect',
      content:
        'We collect information directly from you, automatically when you visit the Site, and from third parties. Personal information includes data that identifies you, such as your name, phone number, email, and IP address. If required by law, we treat certain other identifiers (like cookie data) as personal information. We may also de-identify personal information so that it becomes non-personal. We collect data when you create an account, log in, make a purchase, participate in promotions, contact customer service, submit content, sync contacts, or interact with social media. If you become a Talent User or Getadoodle Partner, we collect additional business-related data. We may also receive information about you from third parties such as analytics services, social media platforms, and marketing partners.',
    },
    {
      title: '3. Cookies and Tracking Technologies',
      content:
        'We use cookies, web beacons, embedded scripts, and other tracking technologies to collect information about your activity on our Site, including device details, browsing history, and interaction with our content. These technologies help improve user experience, save preferences, and analyze trends. Some tracking technologies are necessary for the Site to function, while others enhance usability. You can manage cookie preferences through your browser settings. While some browsers send "Do Not Track" signals, we do not respond to \'Do Not Track\' browser signals, except where legally required, such as in California or the EEA. Getadoodle does not serve targeted advertisements.',
    },
    {
      title: '4. How We Use Information',
      content:
        'We use your information to provide and improve our Site, process transactions, manage accounts, communicate with you, send promotional messages (if opted in), conduct promotions, provide customer support, personalize content, and conduct business analytics. We may use de-identified or aggregated data for business insights. Additionally, we use information to detect fraud, enforce our Terms of Service, and comply with legal obligations. If you apply for a job, we use your information to evaluate your application and comply with legal requirements.',
    },
    {
      title: '5. How We Share Information',
      content:
        'We share personal information with affiliates, service providers, Getadoodle Partners, and compliance partners when necessary for our business operations. If you participate in a promotion, we may share information for prize fulfillment and compliance. If required by law, we disclose information to regulatory authorities or law enforcement. We may also share data when necessary to protect legal rights, prevent fraud, or in connection with business transactions such as mergers or acquisitions. Getadoodle does not sell your personal information to third parties for their marketing purposes without your consent.',
    },
    {
      title: '6. Children and Parents',
      content:
        'Our Site is not intended for users under the age of 4. Users under 13 require parental consent to access features involving user-generated content. We do not knowingly collect personal information from children under 4 years old, and if we become aware of such collection, we will delete the information. If your child has shared unauthorized personal information, please contact us at dataprotection@getadoodle.com for prompt removal.',
    },
    {
      title: '7. Your Choices',
      content:
        'You can manage cookie settings, opt out of marketing emails by clicking the "unsubscribe" link, and stop promotional text messages by replying "STOP" to 22-636. Even if you opt out of marketing, we may still send service-related communications. Getadoodle does not track users for targeted advertising and does not engage in cross-site behavioral advertising. California residents have additional rights under the CCPA (see "Notice to California Residents").',
    },
    {
      title: '8. Accessing Personal Information and Retention Period',
      content:
        'You can access, correct, or delete your personal information through your account settings or by contacting us. If you delete your account, we will remove data from active databases but may retain necessary records for compliance, dispute resolution, and business operations.',
    },
    {
      title: '9. Data Security',
      content:
        'We take commercially reasonable security measures to protect your information but cannot guarantee absolute security. You use our Site at your own risk. If you have security concerns, please contact us.',
    },
    {
      title: '10. Notice to California Residents',
      content:
        'California residents have rights under the CCPA, including the right to access, delete, and opt out of the sale of personal information. Getadoodle does not sell your personal information but engages in certain advertising practices that may be considered a "sale" under the CCPA. To exercise your rights, email dataprotection@getadoodle.com. We will process access and deletion requests within 45 days and opt-out requests within 15 business days. California residents under 18 who have posted content on our Site may request its removal.',
    },
    {
      title: '11. Notice to Nevada Residents',
      content:
        'We do not currently sell personal information as defined by Nevada law. However, Nevada residents may submit an opt-out request by emailing dataprotection@getadoodle.com.',
    },
    {
      title: '12. Notice to UK and EEA Users',
      content:
        'If you are located in the UK or EEA, Getadoodle is the data controller for GDPR purposes. We process your data based on legal grounds such as fulfilling services, consent, legitimate interest, or legal compliance. You have rights to access, delete, or transfer your personal data and can object to certain processing activities. UK and EEA users have additional rights under GDPR, including the right to restrict or object to data processing. To exercise these rights, contact dataprotection@getadoodle.com. Your data may be transferred to the U.S. or other countries with appropriate safeguards in place.',
    },
    {
      title: '13. Notice to Users Outside the U.S., UK, and EEA',
      content:
        'Your data may be processed and stored in the U.S. or other locations where Getadoodle operates. Local laws may apply, and we take reasonable measures to safeguard your privacy. If you do not agree to data collection and transfer, please stop using our Site.',
    },
    {
      title: '14. Contact Us',
      content:
        'For privacy-related inquiries, contact us at Getadoodle Inc., Attention: Data Protection at dataprotection@getadoodle.com.',
    },
  ];

  return (
    <Container>
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-800 mb-2">
            Privacy Policy
          </h1>
          <p className="text-gray-600" style={{fontStyle: 'italic'}}>
            Last Updated March 9, 2024
          </p>
        </div>

        <div className="mb-8">
          <p className="text-gray-700 mb-4">
            We appreciate your trust in our products and services. To provide
            the Getadoodle marketplace platform through our website
            (getadoodle.com), mobile application, and services (collectively,
            the "Site") and improve them, Getadoodle Inc. ("Getadoodle," "we,"
            "us," or "our") collects information from you. If you're a resident
            of California, Nevada, or the European Economic Area ("EEA"),
            additional sections below apply to you. This Privacy Policy explains
            how we collect, store, use, transfer, and disclose your information.
            We may revise this Privacy Policy and notify you by posting updates
            on our Site and updating the "Last Updated" date. Changes take
            effect upon posting unless otherwise stated or required by law. By
            continuing to use our Site, you agree to the revised policy. Please
            also review our <Link to="/terms-of-service">Terms of Service</Link>
            , which apply to your use of our Site.
          </p>
        </div>

        <div className="space-y-6">
          {sections.map((section, index) => (
            <div key={index} className="mb-4">
              <h2 className="text-xl font-semibold text-gray-800 mb-2">
                {section.title}
              </h2>
              <p className="text-gray-700">{section.content}</p>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default PrivacyPolicy;
